<template>
    <AppHeader
        :title="$t('Loadboard')"
        :icon-component="IconLoadboard">
        <template #actions>
            <LoadFilterDropdown
                class="max-w-48 2xl:hidden"
                :root-url="route('loads.index')"
                :filters="filters" />
            <AppFilterPills
                class="hidden gap-x-2 border-r border-iel-gray pr-3 md:ml-auto 2xl:flex"
                :root-url="route('loads.index')"
                :filters="filters" />
            <div>
                <AppButton
                    id="add-load-button"
                    icon="far fa-circle-plus"
                    permission="create_new_load"
                    @click="openLoadCreateModal">
                    {{ $t('New Load') }}
                </AppButton>
            </div>
            <LoadLayoutToggle @change="router.visit(route('loads.index'))" />
        </template>
    </AppHeader>
    <AppCard
        :title="`${load.id}`"
        theme="blue"
        content-class="!p-0">
        <template #title>
            <div
                :id="`load-${load.id}-details-header`"
                class="flex flex-col items-center gap-2 text-xs text-white sm:text-sm lg:flex-row lg:gap-6 lg:text-xs xl:text-sm">
                <a
                    id="back-to-all-loads"
                    :href="route('loads.index')"
                    aria-label="return to all loads">
                    <AppIcon
                        name="far fa-arrow-left"
                        class="text-white" />
                </a>
                <div class="space-x-2 font-semibold uppercase">
                    <span>{{ load.customer_po ?? '' }}</span>
                    <a
                        :href="route('customers.show', load.customer.id)"
                        class="underline">
                        {{ load.customer?.name ?? '' }}
                    </a>
                    <span>#{{ load.po_number }}</span>

                    <div
                        v-if="load.carrier"
                        class="group relative inline space-x-2">
                        <span>|</span>
                        <a :href="route('carriers.show', load.carrier.id)">{{ load.carrier?.name }}</a>
                        <div
                            class="-bottom-15 absolute right-0 z-50 hidden w-32 space-y-1 rounded-md border bg-white p-1 text-sm text-black shadow-md group-hover:block">
                            <p>DOT: {{ load.carrier?.dot_number }}</p>
                            <p>MC: {{ load.carrier?.mc_number }}</p>
                            <p>MX: {{ load.carrier?.mx_number }}</p>
                            <p>FF: {{ load.carrier?.ff_number }}</p>
                        </div>
                    </div>
                </div>
                <AppPhoneLink
                    v-if="load.dispatcher"
                    :tooltip="true"
                    :number="load.dispatcher?.phone_number"
                    :name="load.dispatcher?.name"
                    title="Dispatcher" />

                <AppPhoneLink
                    v-if="load.driver"
                    :tooltip="true"
                    :number="load.driver?.phone_number"
                    :name="load.driver?.name"
                    :title="load.driver2 ? 'Driver 1' : 'Driver'" />

                <AppPhoneLink
                    v-if="load.driver2"
                    :tooltip="true"
                    :number="load.driver2?.phone_number"
                    :name="load.driver2?.name"
                    title="Driver 2" />
                <LoadStopTracker v-if="load.stops.length > 0" />
            </div>
        </template>
        <template #nav>
            <div class="flex items-center gap-4">
                <div
                    v-if="pastDueCount > 0 || warningCount > 0"
                    class="group relative">
                    <AppWarningIcon
                        size="md"
                        class="mr-1" />
                    <span
                        class="absolute inset-x-0 -bottom-8 z-50 hidden w-28 rounded-md border bg-white p-1 text-center text-sm font-semibold text-black shadow-md group-hover:block">
                        {{ alerts }}
                    </span>
                </div>
                <a
                    v-if="load.claim"
                    href="#load-flags-detail-section">
                    <AppIcon
                        name="fas fa-gavel"
                        class="text-white" />
                </a>
                <a
                    v-if="load.carrier_incident"
                    href="#load-flags-detail-section">
                    <AppIcon
                        name="fas fa-handshake"
                        class="text-white" />
                </a>
                <a
                    v-if="load.notes"
                    href="#load-notes-detail-section">
                    <AppIcon
                        name="far fa-notebook"
                        class="text-white" />
                </a>
                <a
                    v-if="load.documents"
                    href="#load-docs-detail-section">
                    <AppIcon
                        name="far fa-file-lines"
                        class="text-white" />
                </a>
                <p
                    v-if="load.is_tonu || load.is_canceled"
                    class="text-iel-light-gray"
                    v-text="load.is_tonu ? 'TONU' : load.is_canceled ? 'CANCELED' : ''"></p>
                <LoadCardActionMenu :load="load" />
                <button @click="toggle">
                    <AppIcon
                        name="far fa-caret-up"
                        class="h-6 w-6 rotate-180 text-white" />
                </button>
            </div>
        </template>
        <div
            v-if="showMenu"
            class="w-full bg-white">
            <LoadDetailDropdown :load="load" />
        </div>
        <LoadPhaseProgress :load="load" />
        <TabGroup
            :selected-index="selectedTab"
            vertical
            @change="i => (selectedTab = i)">
            <TabPanels class="grow overflow-hidden">
                <TabPanel
                    v-for="phase in ['setup', 'plan', 'ship', 'bill']"
                    :key="phase">
                    <LoadDetailPanels :load="load" />
                </TabPanel>
            </TabPanels>
        </TabGroup>
    </AppCard>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { TabGroup, TabPanel, TabPanels } from '@headlessui/vue';
import { allFilters } from '@/Composables/useFilters.js';
import Echo from 'laravel-echo';
import IconLoadboard from '/resources/img/icon-loadboard.svg';
import Pusher from 'pusher-js';
import { Toast } from '@/Composables/useSwal.js';

const props = defineProps({
    load: Object
});

const { open: openLoadCreateModal } = useModal('LoadCreateModal');

const { filters } = allFilters();

const phases = {
    setup: 'fal fa-memo-circle-check',
    plan: 'fal fa-clipboard-list',
    ship: 'fal fa-truck',
    bill: 'fal fa-circle-dollar',
    complete: 'fal fa-circle-check'
};

const showMenu = ref(false);

function toggle() {
    showMenu.value = !showMenu.value;
}

const selectedTab = ref(Object.keys(phases).indexOf(props.load.phase));

window.Pusher = Pusher;

const laravelEcho = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_CLIENT_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    forceTLS: false,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER
});

laravelEcho
    .private(`loads.${props.load.id}`)
    .listen('LoadWasUpdated', () => {
        router.reload({
            preserveScroll: true,
            only: ['load']
        });
    })
    .listen('MacropointOrderEvent', payload => {
        Toast.fire({
            title: payload.macropointResponse.message,
            icon: payload.macropointResponse.result
        });
    });

const warningCount = computed(() => {
    const now = new Date();
    return props.load.tasks.filter(
        task =>
            task.due_at &&
            !task.is_complete &&
            task.warning_minutes !== 0 &&
            now >= new Date(new Date(task.due_at).getTime() - task.warning_minutes * 60000) &&
            now < new Date(task.due_at)
    ).length;
});

const pastDueCount = computed(() => {
    const now = new Date();
    return props.load.tasks.filter(task => task.due_at && !task.is_complete && now >= new Date(task.due_at)).length;
});

const alerts = computed(() => {
    return pastDueCount.value + warningCount.value > 0
        ? `${(pastDueCount.value || 0) + (warningCount.value || 0)} task(s)`
        : '';
});
</script>
