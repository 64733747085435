<template>
    <AppModal
        id="carrier-vehicle-edit-modal"
        :show="isOpen"
        :title="context.title"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="onConfirm">
            <AppInput
                id="carrier-vehicle-vin-input"
                v-model="form.vin"
                label="VIN"
                name="vin"
                required
                @keydown="onlyVinCharacters"
                @input="convertToUpper" />
            <AppCheckbox
                id="carrier-vehicle-scheduled-auto-checkbox"
                v-model="form.scheduled_auto_only"
                name="scheduled_auto_only"
                label="Scheduled Auto" />
            <div
                v-if="form.errors.scheduled_auto_only"
                class="text-sm text-iel-red">
                {{ form.errors.scheduled_auto_only }}
            </div>
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-carrier-vehicle"
                    type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CarrierVehicleEditModal');

const form = useForm({
    vin: null,
    scheduled_auto_only: false
});

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    if (!value) return;

    form.vin = context.value.vehicle.vin;
    form.scheduled_auto_only = context.value.vehicle.scheduled_auto_only;
});

function onConfirm() {
    form.put(
        route('carriers.vehicles.update', {
            carrier: context.value.carrier,
            vehicle: context.value.vehicle
        }),
        {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        }
    );
}

function onlyVinCharacters(event) {
    if (event.key.match(/[^a-hj-npr-zA-HJ-NPR-Z0-9]/)) {
        event.preventDefault();
    }
}

function convertToUpper() {
    form.vin = form.vin.toUpperCase();
}
</script>
