<template>
    <div
        :class="bottomBorder ? 'space-y-0' : 'space-y-1'"
        data-test="wrapper">
        <AppLabel v-bind="{ name: attributes.name, label: fieldLabel, required }" />
        <div class="relative">
            <input
                v-bind="attributes"
                v-model="value"
                v-uppercase="uppercase"
                class="block w-full border-iel-light-gray px-2 placeholder:text-iel-gray sm:text-sm"
                :class="bottomBorder ? 'border-l-0 border-r-0 border-t-0 py-0' : 'rounded py-1'"
                :readonly="readonly"
                :type="type"
                :min="min"
                :step="step"
                :disabled="disabled || unauthorized"
                :pattern="pattern"
                @input="handleInput"
                @blur="handleBlur" />
            <AppRequestStatus
                v-if="hasAutoSave"
                v-bind="{ recentlySuccessful, processing, error }"
                @clear="resetField" />
        </div>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true
    },
    bottomBorder: Boolean,
    label: String,
    error: String,
    scope: [String, Number],
    type: {
        type: String,
        default: 'text'
    },
    placeholder: String,
    id: {
        type: String,
        default: null
    },
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    min: {
        type: Number,
        default: undefined
    },
    step: String,
    url: String,
    confirmation: String,
    permission: String,
    uppercase: Boolean,
    pattern: String
});

const emit = defineEmits(['blur']);

const hasAutoSave = props.url !== undefined;

const form = inject('form', undefined);

const {
    value: autoSaveValue,
    submit,
    processing,
    recentlySuccessful,
    error: autoSaveError,
    clearError
} = useAutoSave(props, 'modelValue');

const definedModel = defineModel({ type: [String, Number] });

const value = hasAutoSave ? autoSaveValue : definedModel;

const error = hasAutoSave ? autoSaveError : computed(() => props.error || form?.errors?.[props.name]);

const { label: fieldLabel, attributes } = useField(props, error);

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));

function handleInput() {
    if (hasAutoSave) {
        clearError();
    } else {
        form?.clearErrors(props.name);
    }
}

function handleBlur() {
    emit('blur', value.value);
    if (hasAutoSave) submit();
}

function resetField() {
    value.value = null;
    clearError();
}
</script>
