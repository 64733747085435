const uppercaseDirective = {
    mounted(el, binding) {
        const handler = () => {
            if (!binding.value) return;
            setTimeout(() => {
                el.value = el.value.toUpperCase();
                el.dispatchEvent(new Event('input'));
            }, 0);
        };

        el._keyupHandler = handler;
        el.addEventListener('keyup', handler);
    },
    unmounted(el) {
        if (el._keyupHandler) {
            el.removeEventListener('keyup', el._keyupHandler);
            delete el._keyupHandler;
        }
    }
};

export { uppercaseDirective };
