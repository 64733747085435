<template>
    <AppModal
        id="carrier-vehicle-create-modal"
        :show="isOpen"
        :title="context.title"
        class="max-w-2xl"
        @close="close"
        @closed="onClosed">
        <div class="mb-4 text-sm italic text-iel-dark-gray">
            VIN must be 17 characters long, containing only the letters A–H, J–N, P–R, Z and digits 0–9.
        </div>
        <form
            class="contents"
            @submit.prevent="submit">
            <table>
                <thead>
                    <tr>
                        <th><AppHeading class="text-left">Vehicle VIN</AppHeading></th>
                        <th><AppHeading>Scheduled Auto</AppHeading></th>
                    </tr>
                </thead>
                <tr
                    v-for="(vehicle, index) in vehicles"
                    :key="index">
                    <td>
                        <AppInput
                            id="carrier-vehicle-vin-input"
                            v-model="vehicles[index].vin"
                            class="flex-1"
                            label=""
                            name="vin"
                            required
                            uppercase
                            pattern="[a-hj-npr-zA-HJ-NPR-Z0-9]+" />
                        <AppErrorMessage
                            :name="`vehicles.${index}.vin`"
                            :error="errors[`vehicles.${index}.vin`]" />
                    </td>
                    <td>
                        <AppCheckbox
                            id="carrier-vehicle-scheduled-auto-checkbox"
                            v-model="vehicles[index].scheduled_auto_only"
                            name="scheduled_auto_only"
                            centered
                            label="" />
                    </td>
                    <td>
                        <AppButton
                            icon="far fa-trash-can"
                            variant="outline"
                            @click="removeRow(index)" />
                    </td>
                </tr>
            </table>

            <div>
                <AppButton
                    id="carrier-vehicle-add-row"
                    icon="far fa-circle-plus"
                    @click="addRow">
                    Add Row
                </AppButton>
            </div>

            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-carrier-vehicle"
                    type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CarrierVehicleCreateModal');

const vehicleTemplate = { vin: null, scheduled_auto_only: false };

const vehicles = ref([]);

const errors = ref({});

watch(isOpen, () => {
    vehicles.value = [structuredClone(vehicleTemplate)];
    errors.value = {};
});

function addRow() {
    vehicles.value.push(structuredClone(vehicleTemplate));
}

function removeRow(index) {
    vehicles.value.splice(index, 1);
}

function submit() {
    errors.value = {};

    router.post(
        route('carriers.vehicles.store', {
            carrier: context.value.carrier
        }),
        {
            vehicles: vehicles.value
        },
        {
            preserveScroll: true,
            onSuccess() {
                close();
            },
            onError(e) {
                errors.value = e;
            }
        }
    );
}
</script>
