<template>
    <TabGroup
        :selected-index="selectedTab"
        as="div"
        @change="i => (selectedTab = i)">
        <TabList class="-mx-2 flex bg-iel-light-gray py-1">
            <Tab
                :id="`load-card-${load.id}-summary-tab`"
                class="chevron relative -ml-2 grow bg-white text-iel-blue first:ml-0">
                <span>Summary</span>
            </Tab>
            <Tab
                v-for="phase in load.phases"
                :id="`load-card-${load.id}-${phase.name}-phase-tab`"
                :key="`load-card-${load.id}-${phase.name}-phase-tab`"
                class="chevron relative -ml-2 grow first:ml-0 last:invisible ui-selected:!bg-white ui-selected:!text-iel-blue"
                :class="{
                    'bg-iel-blue text-white': phase.status === 'complete',
                    'bg-iel-gray/50 text-iel-blue': phase.status === 'pending',
                    'bg-iel-light-gray text-iel-blue': phase.status === 'incomplete'
                }">
                <div class="flex flex-col items-center gap-1 p-2">
                    <AppIcon
                        :name="phaseIcons[phase.name]"
                        class="h-6 w-6" />
                    <span
                        class="text-sm"
                        v-text="$t(phase.label)" />
                </div>
                <div
                    v-if="phase.name !== 'complete'"
                    class="chevron-line absolute inset-0 hidden bg-iel-blue ui-selected:block"></div>
            </Tab>
        </TabList>

        <TabPanels class="grow overflow-hidden">
            <TabPanel
                v-for="phase in ['complete', 'setup', 'plan', 'ship', 'bill']"
                :key="phase"
                :class="
                    phase === 'complete'
                        ? 'hidden'
                        : 'small-scrollbar flex gap-5 overflow-x-auto overflow-y-hidden whitespace-nowrap px-1 py-2'
                "
                tabindex="0">
                <template
                    v-for="task in tasksByPhase[phase]"
                    :key="task.id">
                    <LoadCheckboxButton
                        :id="`loadcard-${task.type}-${task.phase}-${task.id}-${task.name.replaceAll('_', '-')}-task-button`"
                        :class="{ hidden: task.name === 'dispatch' && !load.stops.length }"
                        :checked="task.is_complete"
                        :load="load"
                        :task="task"
                        permission="edit_load_tasks"
                        @complete-task="handleTaskClick(task)">
                        {{ $t(startCase(task.name)) }}
                    </LoadCheckboxButton>
                </template>
            </TabPanel>
        </TabPanels>
        <hr
            v-if="selectedTab !== 4"
            class="-mx-2" />
    </TabGroup>
</template>

<script setup>
import { toRef } from 'vue';
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from '@headlessui/vue';
import { startCase } from 'lodash-es';

const phaseIcons = {
    setup: 'fal fa-memo-circle-check',
    plan: 'fal fa-clipboard-list',
    ship: 'fal fa-truck',
    bill: 'fal fa-circle-dollar',
    complete: 'fal fa-circle-check'
};

const props = defineProps({
    load: Object
});

const { tasksByPhase, handleTaskClick } = useTasks(toRef(props, 'load'));

const selectedTab = ref(Object.keys(phaseIcons).indexOf(props.load.phase));
</script>

<style scoped>
.chevron {
    --offset: theme('spacing.3');
    --shape: polygon(
        calc(100% - var(--offset)) 0%,
        100% 50%,
        calc(100% - var(--offset)) 100%,
        0% 100%,
        var(--offset) 50%,
        0% 0%
    );
    clip-path: var(--shape);
    shape-outside: var(--shape);
}

.chevron-line {
    --offset: theme('spacing.3');
    --width: 3px;
    --shape: polygon(
        calc(100% + var(--offset)) 0%,
        calc(100% - var(--offset)) 100%,
        calc(100% - var(--offset) - var(--width)) 100%,
        calc(100% + var(--offset) - var(--width)) 0%
    );
    clip-path: var(--shape);
    shape-outside: var(--shape);
}

.chevron:first-child {
    /* No left cutout on the far left */
    --shape: polygon(calc(100% - var(--offset)) 0%, 100% 50%, calc(100% - var(--offset)) 100%, 0% 100%, 0% 0%);
}

.chevron:last-child {
    /* No right cutout on the far right */
    --shape: polygon(100% 0%, 100% 100%, 0% 100%, var(--offset) 50%, 0% 0%);
}
</style>
